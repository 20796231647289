import {
  FlagWordsModel,
  IFindFlagWordsByTypeValueReqModel,
  IFindFlagWordsByTypeValuesReqModel,
  MarkAsBadFlagWord
} from '@eros-api/models';
import { createAction, props } from '@ngrx/store';

const flagWordDataNamespace = '[Global][Flag Word]';

export const loadFlagWords = createAction(
  `${flagWordDataNamespace} Load Flag Words`,
  props<{
    reqBody: IFindFlagWordsByTypeValueReqModel[];
  }>()
);
export const loadFlagWordsSuccess = createAction(
  `${flagWordDataNamespace} Load Flag Words Success`,
  props<{ flagWords: FlagWordsModel[] }>()
);
export const loadFlagWordsFailure = createAction(
  `${flagWordDataNamespace} Load Flag Words Failure`,
  props<{ error: Error }>()
);

export const loadFlagWordsByValues = createAction(
  `${flagWordDataNamespace} Load Flag Words By Values`,
  props<{
    reqBody: IFindFlagWordsByTypeValuesReqModel[];
  }>()
);
export const loadFlagWordsByValuesFailure = createAction(
  `${flagWordDataNamespace} Load Flag Words By Values Failure`,
  props<{ error: Error }>()
);

export const creatFlagWord = createAction(
  `${flagWordDataNamespace} Create Flag Word`,
  props<{ reqBody: MarkAsBadFlagWord }>()
);
export const creatFlagWordSuccess = createAction(
  `${flagWordDataNamespace} Create Flag Word Success`,
  props<{ flagWord: FlagWordsModel }>()
);
export const creatFlagWordFailure = createAction(
  `${flagWordDataNamespace} Create Flag Word Failure`,
  props<{ error: Error }>()
);

export const deleteFlagWord = createAction(
  `${flagWordDataNamespace} Delete One Flag Word`,
  props<{ id: string }>()
);
export const deleteFlagWordSuccess = createAction(
  `${flagWordDataNamespace} Delete One Flag Word Success`,
  props<{ id: string }>()
);
export const deleteFlagWordFailure = createAction(
  `${flagWordDataNamespace} Delete One Flag Word Failure`,
  props<{ error: Error }>()
);
