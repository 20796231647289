import { createAction, props } from '@ngrx/store';
import { OpsAccountModel } from '@eros-api/models';

const actionsNamespace = '[Global][Ops Accounts]';

export const loadAccounts = createAction(`${actionsNamespace} Load All`);

export const loadAccountsSuccess = createAction(
  `${actionsNamespace} Load All Success`,
  props<{ opsAccounts: OpsAccountModel[] }>()
);

export const loadAccountsFailure = createAction(
  `${actionsNamespace} Load All Failure`,
  props<{ error: Error }>()
);
