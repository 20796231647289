export class FormDataCategory {
  label: string;
  type: string;
  id: number;

  constructor(id: number, label: string, type: string) {
    this.id = id;
    this.label = label;
    this.type = type;
  }
}

export enum FormDataKeyEnum {
  Attributes = 1,
  MainCategory,
  Specialty,
  Locations
}

export interface SecFormDataCategoryGroup {
  name: string;
  categories: FormDataCategory[];
}
