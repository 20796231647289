import { FormDataModel } from '@eros-api/models/form-data.model';
import { createAction, props } from '@ngrx/store';

const formDataNamespace = '[Global][Form Data]';

export const loadFormData = createAction(`${formDataNamespace} Load All`);

export const loadFormDataSuccess = createAction(
  `${formDataNamespace} Load All Success`,
  props<{ formData: FormDataModel }>()
);

export const loadFormDataFailure = createAction(
  `${formDataNamespace} Load All Failure`,
  props<{ error: Error }>()
);
