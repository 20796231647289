import {
  Attributes,
  FormDataModel,
  LocationCategories,
  MainCategory,
  SpecialtyCategory
} from '@eros-api/models/form-data.model';

import {
  FormDataCategory,
  FormDataKeyEnum,
  SecFormDataCategoryGroup
} from '../models/form-data.model';

const INTERNAL_CATEGORIES: FormDataCategory[] = [
  new FormDataCategory(31, 'incall', 'available_for'),
  new FormDataCategory(32, 'outcall', 'available_for'),
  new FormDataCategory(37, 'visiting', 'internal'),
  new FormDataCategory(39, 'super busty', 'internal'),
  new FormDataCategory(622, 'all', 'internal'),
  new FormDataCategory(623, 'available now', 'internal'),
  new FormDataCategory(630, 'video', 'internal'),
  new FormDataCategory(5, 'vip', 'internal'),
  new FormDataCategory(629, 'authenticated', 'authenticated')
];

const transformAttributes = (attributes?: Attributes) => {
  if (!attributes) {
    return [];
  }

  const result: FormDataCategory[] = [];

  for (let key in attributes) {
    if (!attributes.hasOwnProperty(key)) continue;

    let secCategories = attributes[key].secCategories;
    if (secCategories) {
      for (let id in secCategories) {
        if (!secCategories.hasOwnProperty(id)) continue;
        result.push(new FormDataCategory(+id, secCategories[id], key));
      }
      continue;
    }

    attributes[key].values.forEach((name, index) => {
      let erosCategoryIds = attributes[key].eros_category_ids;

      if (!erosCategoryIds) {
        return;
      }

      result.push(new FormDataCategory(erosCategoryIds[index], name, key));
    });
  }

  result.push(...INTERNAL_CATEGORIES);

  return result;
};

const transformSpecialtyCategories = (
  specialtyCategories?: SpecialtyCategory[]
): FormDataCategory[] => {
  if (!specialtyCategories) {
    return [];
  }

  return specialtyCategories.map(
    (cat) => new FormDataCategory(cat.eros_id, cat.value, 'specialty')
  );
};

const transformMainCategories = (
  mainCategories?: MainCategory[]
): FormDataCategory[] => {
  if (!mainCategories) {
    return [];
  }

  return mainCategories.map(
    (cat) => new FormDataCategory(cat.eros_id, cat.value, 'main')
  );
};

const transformLocationCategories = (
  locationCategories?: LocationCategories
): FormDataCategory[] => {
  if (!locationCategories) {
    return [];
  }

  const result: FormDataCategory[] = [];

  for (const requiredLocationId in locationCategories) {
    if (!locationCategories.hasOwnProperty(requiredLocationId)) {
      continue;
    }

    locationCategories[requiredLocationId].forEach((cat) => {
      result.push(new FormDataCategory(cat.eros_id, cat.value, 'location'));
    });
  }

  result.push(...INTERNAL_CATEGORIES);

  return result;
};

export const getSecondaryCategories = (
  addInternals: boolean,
  whatToInclude: FormDataKeyEnum[],
  formData?: FormDataModel
): FormDataCategory[] => {
  let secCategories: FormDataCategory[] = [];

  if (whatToInclude.includes(FormDataKeyEnum.Attributes))
    secCategories.push(...transformAttributes(formData?.attributes));

  if (whatToInclude.includes(FormDataKeyEnum.MainCategory))
    secCategories.push(...transformMainCategories(formData?.main_categories));

  if (whatToInclude.includes(FormDataKeyEnum.Specialty))
    secCategories.push(
      ...transformSpecialtyCategories(formData?.specialty_categories)
    );

  if (whatToInclude.includes(FormDataKeyEnum.Locations))
    secCategories.push(
      ...transformLocationCategories(formData?.location_categories)
    );

  if (addInternals) {
    secCategories.push(...INTERNAL_CATEGORIES);
  }

  return secCategories;
};

export const getGroupedSecCategories = (
  addInternals: boolean,
  whatToInclude: FormDataKeyEnum[],
  formData?: FormDataModel
): SecFormDataCategoryGroup[] => {
  const result = [];
  getSecondaryCategories(addInternals, whatToInclude, formData).forEach(
    (cat) => {
      let group = result.find((g) => g.name === cat.type);
      if (!group) {
        group = { name: cat.type, categories: [] };
        result.push(group);
      }
      group.categories.push(cat);
    }
  );

  return result;
};
