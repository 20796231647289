import { createAction, props } from '@ngrx/store';
import {
  AccountUpdatePasswordReqPayload,
  OpsAccountModel
} from '@eros-api/models';

import { createDomainActionType } from '../utils';

const actionsNamespace = createDomainActionType('Auth');

export const checkAccessToken = createAction(
  `${actionsNamespace} Check Access Token`
);

export const checkPermissions = createAction(
  `${actionsNamespace} Check Permissions`,
  props<{ username: string; password: string }>()
);

export const saveUrlForRedirectionAfterLogin = createAction(
  `${actionsNamespace} Save Url For Redirection After Login`,
  props<{ url: string }>()
);

export const login = createAction(
  `${actionsNamespace} Login`,
  props<{ username: string; password: string }>()
);

export const loginSuccess = createAction(
  `${actionsNamespace} Login Success`,
  props<{ account: OpsAccountModel }>()
);

export const loginFailure = createAction(
  `${actionsNamespace} Login Failure`,
  props<{ error: Error }>()
);

export const activateChangePassword = createAction(
  `${actionsNamespace} Activate Change Password`,
  props<{ accountId: number; username: string; token: string }>()
);

export const changePassword = createAction(
  `${actionsNamespace} Change Password`,
  props<{
    accountId: number;
    reqBody: AccountUpdatePasswordReqPayload;
    token: string;
  }>()
);

export const changePasswordSuccess = createAction(
  `${actionsNamespace} Change Password Success`
);

export const changePasswordFailure = createAction(
  `${actionsNamespace} Change Password Failure`,
  props<{ error: Error }>()
);

export const navigateToApp = createAction(
  `${actionsNamespace} Navigate To App`,
  props<{
    urlForRedirectionAfterLogin: string | null;
  }>()
);

export const logout = createAction(`${actionsNamespace} Logout`);

export const confirmLogout = createAction(`${actionsNamespace} Confirm Logout`);

export const dismissLogoutConfirmation = createAction(
  `${actionsNamespace} Dismiss Logout Confirmation`
);

export const logoutComplete = createAction(
  `${actionsNamespace} Logout Complete`
);
