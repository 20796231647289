import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromFormData from '@app/store/reducers/form-data.reducer';

import {
  getGroupedSecCategories,
  getSecondaryCategories
} from '../utils/form-data-helper';
import { FormDataKeyEnum } from '../models/form-data.model';

export const selectOpsAccountsState =
  createFeatureSelector<fromFormData.FormDataState>(fromFormData.featureKey);

export const { selectAll } = fromFormData.entityAdapter.getSelectors(
  selectOpsAccountsState
);

export const selectCallState = createSelector(
  selectOpsAccountsState,
  (state) => state.callState
);

export const selectFormData = createSelector(selectAll, (data) => data[0]);

export const selectGenderList = createSelector(
  selectFormData,
  (formData) => formData?.attributes?.gender?.values ?? []
);

export const selectMainCategories = createSelector(
  selectFormData,
  (formData) => formData?.main_categories ?? []
);

export const selectSecondaryCategories = (
  addInternals = true,
  whatToInclude: FormDataKeyEnum[] = [
    FormDataKeyEnum.Attributes,
    FormDataKeyEnum.MainCategory,
    FormDataKeyEnum.Specialty,
    FormDataKeyEnum.Locations
  ]
) =>
  createSelector(selectFormData, (formData) =>
    getSecondaryCategories(addInternals, whatToInclude, formData)
  );

export const selectGroupedSecCategories = (
  addInternals = true,
  whatToInclude: FormDataKeyEnum[] = [
    FormDataKeyEnum.Attributes,
    FormDataKeyEnum.MainCategory,
    FormDataKeyEnum.Specialty,
    FormDataKeyEnum.Locations
  ]
) =>
  createSelector(selectFormData, (formData) =>
    getGroupedSecCategories(addInternals, whatToInclude, formData)
  );

export const selectSpecialtyCategories = createSelector(
  selectFormData,
  (formData) => formData?.specialty_categories ?? []
);

export const selectVipRequiredMainCats = createSelector(
  selectFormData,
  (formData) => formData?.configs.vip_required_main_cats ?? []
);

export const selectAAVersion = createSelector(
  selectFormData,
  (formData) => formData?.configs.aa_version
);
