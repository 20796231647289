import { createAction, props } from '@ngrx/store';

import { createFeatureActionType } from '@app/store/utils';
import { AccountModel } from '@eros-api/models';
import { FraudDetectionResult } from '@eros-api/models/IPMatchModel';

export const fraudNamespace = createFeatureActionType('Fraud Widget');

export const findMatchingByIpAccountIdCookie = createAction(
  `${fraudNamespace} Find Matching By IP Account ID Cookie`,
  props<{ accountId: number }>()
);
export const findMatchingByIpAccountIdCookieSuccess = createAction(
  `${fraudNamespace} Find Matching By IP Account ID Cookie Success`,
  props<{
    detected: FraudDetectionResult;
    accountsMap: { [key: string]: Partial<AccountModel> };
    isFound: boolean;
  }>()
);
export const findMatchingByIpAccountIdCookieFailure = createAction(
  `${fraudNamespace} Find Matching By IP Account ID Cookie Failure`,
  props<{ error: Error }>()
);

export const cleanupIpCookieConnections = createAction(
  `${fraudNamespace} Cleanup IP And Cookie Connections`,
  props<{ accountId: number }>()
);
export const cleanupIpCookieConnectionsSuccess = createAction(
  `${fraudNamespace} Cleanup IP And Cookie Connections Success`,
  props<{ accountId: number }>()
);
export const cleanupIpCookieConnectionsFailure = createAction(
  `${fraudNamespace} Cleanup IP And Cookie ConnectionsFailure`,
  props<{ error: Error }>()
);

export const resetFraudStore = createAction(
  `${fraudNamespace} Reset Fraud Store`
);
