import { FormDataModel } from '@eros-api/models/form-data.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { formDataActions } from '../actions';
import { CallState, createDomainFeatureKey } from '../utils';

export const featureKey = createDomainFeatureKey('form-data');

export interface FormDataState extends EntityState<FormDataModel> {
  callState: CallState;
}

export const entityAdapter: EntityAdapter<FormDataModel> =
  createEntityAdapter<FormDataModel>({
    selectId: (_entity) => 'form-data-item',
    sortComparer: false
  });

const initialState: FormDataState = entityAdapter.getInitialState({
  callState: CallState.Init
});

export const reducer = createReducer(
  initialState,
  on(formDataActions.loadFormData, (state) => ({
    ...state,
    callState: CallState.Loading
  })),
  on(formDataActions.loadFormDataSuccess, (state, { formData }) =>
    entityAdapter.setOne(formData, { ...state, callState: CallState.Loaded })
  ),
  on(formDataActions.loadFormDataFailure, (state) => ({
    ...state,
    callState: CallState.Error
  }))
);
