import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormDataModel } from '@eros-api/models/form-data.model';

import { map, Observable } from 'rxjs';
import { JsonObject, JsonArray, RequireAtLeastOne } from 'type-fest';

import {
  AbstractErosApiConfig,
  throwErrorIfErosApiConfigIsMissing
} from '../core/eros-api-config';
import {
  ProfilePersonModel,
  ProfilePersonCreateReqPayload,
  ProfilePersonMergeResPayload,
  ProfileAttributesResponse,
  PersonUpdateAuthenticatedReqPayload,
  PersonLivenessVerificationStatus
} from '../models/profile-person.model';

/**
 * @link https://api.eros.com.sceon.am/escort-profile/apidoc/#api-Person
 * */
@Injectable({
  providedIn: 'root'
})
export class ProfilePersonService {
  private readonly basePath: string;

  constructor(
    @Optional()
    @Inject(AbstractErosApiConfig)
    private config: AbstractErosApiConfig,
    private http: HttpClient
  ) {
    throwErrorIfErosApiConfigIsMissing(config);

    this.basePath = `${config.getApiUrl()}/escort-profile`;
  }

  getById(personId: string): Observable<ProfilePersonModel> {
    const url = `${this.basePath}/person/${personId}`;

    return this.http
      .get<JsonObject>(url)
      .pipe(map((json) => ProfilePersonModel.fromJSON(json)));
  }

  create(data: ProfilePersonCreateReqPayload): Observable<{ id: string }> {
    const url = `${this.basePath}/persons`;

    const { name, accountId } = data;

    const body = ProfilePersonModel.toJSON({ name, accountId });

    return this.http.post<{ id: string }>(url, body);
  }

  update(data: Partial<ProfilePersonModel>): Observable<unknown> {
    const url = `${this.basePath}/person/${data.id}`;

    const body = {
      _id: data.id,
      id_bypass: data.idBypass,
      name: data.name
    };

    return this.http.put<unknown>(url, body);
  }

  activate(personId: string): Observable<unknown> {
    const url = `${this.basePath}/person/${personId}/activate`;

    return this.http.post<unknown>(url, {});
  }

  deactivate(personId: string): Observable<unknown> {
    const endpoint = `${this.basePath}/person/${personId}/deactivate`;

    return this.http.post<unknown>(endpoint, {});
  }

  merge(
    source: string,
    destination: string
  ): Observable<ProfilePersonMergeResPayload> {
    const url = `${this.basePath}/persons/_merge`;

    const body = {
      src: source,
      dst: destination
    };

    return this.http.post<ProfilePersonMergeResPayload>(url, body);
  }

  getList(accountId?: number): Observable<ProfilePersonModel[]> {
    const url = `${this.basePath}/persons`;

    let params = new HttpParams();

    if (accountId) {
      params = params.set('account_id', accountId);
    }

    return this.http
      .get<JsonArray>(url, { params })
      .pipe(map((json) => json.map((rec) => ProfilePersonModel.fromJSON(rec))));
  }

  resetVerification(personId: string): Observable<{ result: boolean }> {
    const url = `${this.basePath}/person/${personId}/_reset-verification`;

    return this.http.post<{ result: boolean }>(url, {});
  }

  requestLivnessCheck(
    personId: string,
    status: PersonLivenessVerificationStatus
  ): Observable<unknown> {
    const url = `${this.basePath}/person/${personId}/request-liveness-check`;

    return this.http.patch<unknown>(url, {
      status
    });
  }

  updateAuthenticated(
    data: PersonUpdateAuthenticatedReqPayload
  ): Observable<unknown> {
    const url = `${this.basePath}/person/${data.personId}/_authenticated`;

    return data.isAuthenticated
      ? this.http.post<unknown>(url, {})
      : this.http.delete<unknown>(url);
  }

  getAttributes(): Observable<ProfileAttributesResponse> {
    const url = `${this.basePath}/attributes`;

    return this.http.get<ProfileAttributesResponse>(url);
  }

  getFormData(): Observable<FormDataModel> {
    const url = `${this.basePath}/form-data`;

    return this.http.get<FormDataModel>(url);
  }
}
